import React, {useEffect, useState} from 'react';
import {Dropdown, IDropdownOption} from '@fluentui/react/lib/Dropdown';
import {TextField, PrimaryButton} from '@fluentui/react';
import {Overlay} from "../../../api";
import {OverlayCrud} from "./OverlayCrud";
import {DocumentCRUD} from "../documents/documentCrud";
import {Document} from "../../../api";
import {useLocation} from 'react-router-dom';
import {twMerge} from "tailwind-merge";
import autoprefixer from "autoprefixer";

const OverlayManager: React.FC = () => {
  const [overlays, setOverlays] = useState<Overlay[]>([]);
  const [prefixes, setPrefixes] = useState<string[]>([]);
  const [selectedPrefix, setSelectedPrefix] = useState<string | undefined>();
  const [selectedOverlay, setSelectedOverlay] = useState<Overlay | undefined>(undefined);
  const overlayService = new OverlayCrud();
  const [documents, setDocuments] = useState<Document[]>([]);
  const location = useLocation();

  function toPrefix(overlay: Overlay): string {
    let parts = overlay.id.split('/')
    if (parts[0] in ['san_diego']) {
      return `${parts[0]}/${parts[1]}`
    }
    return parts[0]
  }

  const loadOverlays = async () => {
    try {
      const data = await overlayService.getOverlays();
      setPrefixes(Array.from(new Set(data.map(toPrefix))));

      setOverlays(data);
      return data;
    } catch (error) {
      console.error('Failed to fetch overlays:', error);
      return [];
    }
  };

  useEffect(() => {
    const fetchDataAndSetOverlay = async () => {
      const fetchedOverlays = await loadOverlays();
      const params = new URLSearchParams(location.search);
      const overlayId = params.get('overlay');
      if (overlayId) {
        const overlay = fetchedOverlays.find(o => o.id === overlayId);
        if (overlay) {
          setSelectedOverlay(overlay);
          setSelectedPrefix(toPrefix(overlay));
          setSelectedOverlay(overlay)
        }
      }
    };

    fetchDataAndSetOverlay();
  }, [location]);

  useEffect(() => {
    if (!(selectedOverlay) || !selectedPrefix || toPrefix(selectedOverlay!) != selectedPrefix) {
      setSelectedOverlay(undefined);
    }
  }, [selectedPrefix]);

  const getDocsForOverlay = async () => {
    if (!selectedOverlay) return;
    let documentCrud = new DocumentCRUD()
    let documents = await documentCrud.getAllDocumentsForOverlay(selectedOverlay?.id);
    setDocuments(documents);
  };

  useEffect(() => {
    getDocsForOverlay()
  }, [selectedOverlay]);

  const handleOverlayChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    const overlay = overlays.find(o => o.id === option?.key);
    setSelectedOverlay(overlay);

    // Update URL when overlay is changed
    const currentUrl = new URL(window.location.href);

    // Remove the overlay parameter from the main URL
    currentUrl.searchParams.delete('overlay');

    // Get the current hash (including the '#' symbol)
    let hash = currentUrl.hash;

    // If there's a hash, update or add the overlay parameter to it
    if (hash) {
      const hashUrl = new URL(hash.slice(1), 'http://dummy.com');
      if (overlay) {
        hashUrl.searchParams.set('overlay', overlay.id);
      } else {
        hashUrl.searchParams.delete('overlay');
      }
      hash = '#' + hashUrl.pathname + hashUrl.search;
    }

    // Set the new URL with the updated hash
    const newUrl = currentUrl.origin + currentUrl.pathname + hash;
    window.history.pushState({}, '', newUrl);
  };

  const handleInputChange = (field: keyof Overlay, value: string) => {
    if (selectedOverlay) {
      setSelectedOverlay({...selectedOverlay, [field]: value});
    }
  };

  const handleUpdateOverlay = async () => {
    if (selectedOverlay) {
      try {
        await overlayService.updateOverlay(selectedOverlay.id, {
          name: selectedOverlay.name,
          description: selectedOverlay.description,
          url: selectedOverlay.url,
        });
        await loadOverlays(); // Refresh the list
      } catch (error) {
        console.error('Failed to update overlay:', error);
      }
    }
  };

  return (
    <div className="p-4">
      <Dropdown
        placeholder="Select an region"
        options={prefixes.map((prefix) => ({
          key: prefix,
          text: prefix,
        }))}
        onChange={(_, option) => setSelectedPrefix(option?.key?.toString() ?? '')}
        selectedKey={selectedPrefix}
        className="mb-4"
      />
      <Dropdown
        placeholder="Select an overlay"
        options={overlays
          .filter(overlay => toPrefix(overlay) == selectedPrefix)
          .map((overlay) => ({
          key: overlay.id,
          text: overlay.name?.length ? overlay.id + ' : ' + overlay.name : overlay.id,
        }))}
        onChange={handleOverlayChange}
        selectedKey={selectedOverlay?.id}
        className="mb-4"
      />

      {selectedOverlay && (
        <div key={selectedOverlay?.id ?? ''}>
          <div className={twMerge("bg-white p-4 rounded-lg shadow-md", selectedOverlay ? '' : 'hidden')}>
            <TextField
              label="Name"
              value={selectedOverlay.name}
              onChange={(e, newValue) => handleInputChange('name', newValue || '')}
              className="mb-4"
            />
            <TextField
              label="Description"
              multiline
              rows={4}
              value={selectedOverlay.description}
              onChange={(e, newValue) => handleInputChange('description', newValue || '')}
              className="mb-4"
            />
            <TextField
              label="URL"
              value={selectedOverlay.url ?? ''}
              onChange={(e, newValue) => handleInputChange('url', newValue || '')}
              className="mb-4"
            />
            <PrimaryButton text="Update Overlay" onClick={handleUpdateOverlay}/>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mt-3">
            <h2>Documents</h2>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
              {documents.map((document) => (
                <a
                  href={`/admin/documents?document=${document.id}`}
                  key={document.id}
                  className="no-underline"
                >
                  <div
                    className="border rounded-lg p-4 bg-white shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out cursor-pointer">
                    <div className="flex flex-col">
                      <span className="text-lg font-semibold text-blue-600 mb-2">{document.name}</span>
                      <span className="text-sm text-gray-600">{document.id}</span>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>

      )}
    </div>
  );
};

export default OverlayManager;
